import React from 'react';
import Container from 'react-bootstrap/Container';
import FilamentTable from './table';
import './App.css';

const App = () => (
  <Container className="container p-3">
    <Container className="p-5 mb-4 bg-light rounded-3">
      <h1 className="header">PLA</h1>
      <FilamentTable/>
    </Container>
  </Container>
);

export default App;
