/* import { useState, useEffect } from 'react'; */
/* import Table from 'react-bootstrap/Table'; */
import * as Icon from 'react-bootstrap-icons';

import filamentData from './data.json';

function FilamentTable() {
  return (
    <div className='container filament'>
      {filamentData && filamentData.length>0 && filamentData.map(({name, brand, image, url, price, currency}) => (
        <div className='row align-items-center' key={name.replace(' ','')}>
          <div className='col-6 col-sm-3'><img src={image} className="filament-img" alt='' /></div>
          <div className='col-6 col-sm-3'>{name}</div>
          <div className='col-3 d-none d-sm-block d-md-block d-lg-block d-xl-block'>{brand}</div>
          <div className='col-2 col-sm-0 d-none d-sm-none d-md-block d-lg-block d-xl-block'>{price} {currency}</div>
          <div className='col-1 d-none d-sm-block d-md-block d-lg-block d-xl-block'><a href={url} target={'_blank'} rel={'noreferrer'}><Icon.Link45deg size={28}/></a></div>
        </div>
      ))}
    </div>
  );
}

export default FilamentTable;